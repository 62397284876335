.statusSuccess {
  background-color: green !important;
  color: white;
}

.statusLoading {
  background-color: orange !important;
  color: white;
}

.statusError {
  background-color: red !important;
  color: white;
}
