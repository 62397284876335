@import "../../../../styles/colors";

.container {
  border-radius: 5px;
  position: relative;
  background-color: #f4f4f4;
  padding: 10px;
}

.input {
  padding: 0 0 0 32px;
  // border-bottom: 1px solid black;
}

.searchIcon {
  padding: 3px;
  position: absolute;
  top: 0;
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 600px) {
  .container {
    max-width: 300px;
  }
}
