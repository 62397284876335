.main {
  width: 100%;
  max-width: 1000px;
  padding: 0 20px;
  margin: 0 auto;
  flex-grow: 1;
}

@media screen and (min-width: 1020px) {
  .main {
    padding: 0;
  }
}
