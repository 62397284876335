.fileInput {
  display: none;
}

.button {
  flex: 1;
  position: relative;
  padding: 16px 30px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  font-size: inherit;
  color: inherit;
  width: 100%;
}

.progress {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12;
  margin-left: -12;
}

.quota {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  margin-top: 5px;
  text-align: center;
  flex: 1;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
