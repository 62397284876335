@import "../../../../styles/colors";

.submit-button {
  width: 100%;
  padding: 11px;
  border: none;
  border-radius: 50px;
  font-size: 21px;
  font-weight: bold;
  margin-top: 15px;
  cursor: pointer;
  color: $white;
  //background: desaturate(#00D4FF, 30%);
  //background: linear-gradient(
  //                20deg,
  //                desaturate(#00D4FF, 30%) 0%,
  //                lighten(desaturate(#00D4FF, 30%), 10%) 100%
  //);
  background: $darkgrey;
  background: linear-gradient(
    20deg,
    $darkgrey 0%,
    lighten($darkgrey, 10%) 100%
  );
}
