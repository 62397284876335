@import "../../../../styles/colors";

.form-field {
  margin: 20px 0;

  &__label {
    display: block;
    font-size: 13px;
    margin-bottom: 2px;
  }

  &__input {
    padding: 16px;
    font-size: 20px;
    width: 100%;

    border: none;
    border-bottom: 2px solid $lightgrey;
    background: none;

    &::placeholder {
      color: $lightgrey;
    }
  }

  &__error-msg {
    color: $error;
    font-size: 13px;
    margin-top: 4px;
  }
}
