@import "../../../../styles/colors";

.login-form {
  &__heading {
    text-align: center;
    margin-bottom: 30px;
    font-weight: bold;
  }

  &__server-error-msg {
    color: $error;
    margin-bottom: 30px;
  }

  &__reset-link {
    text-decoration: none;
    margin-top: 5px;
    color: $darkgrey;
    border: none;
    background: none;
    font-size: inherit;
    text-align: right;
    width: 100%;
  }
}
