@import "../../styles/colors";

.login-screen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    width: 100%;
    max-width: 500px;
    padding: 30px 20px 50px;
    margin: 0 auto;
    // border: solid 1px black;
    background-color: $white;
    border-radius: 17px;
  }
}

@media screen and (min-width: 550px) {
  .login-screen__container {
    padding: 30px 60px 50px;
  }
}
