@import "../../../../styles/colors";

.reset-form {
  font-size: 18px;
  line-height: 1.6;

  &__heading {
    margin-bottom: 20px;
  }

  &__back-button {
    margin-right: 20px;
    cursor: pointer;
    border: none;
    background: none;
    font-size: inherit;
  }

  &__text {
  }

  &__error-msg {
    margin-top: 20px;
    color: $error;
  }
}
