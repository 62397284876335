.header {
  padding: 20px 0 0;
  display: flex;
  flex-direction: column;
}

.headerSection {
  margin-bottom: 20px;
}

.heading {
  margin-bottom: 0;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 600px) {
  .header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
  }

  .headerSection {
    margin-bottom: 0;
  }

  .uploadButton {
    float: right;
  }
}

@media screen and (min-width: 1000px) {
  .headerSection {
    width: 100%;
  }
}
