@import "../../styles/colors";

.layout {
  display: flex;
  flex-flow: column;
  height: 100%;

  background: $white;

  &.login {
    background: rgb(127, 255, 212);
    background: linear-gradient(
      217deg,
      rgba(127, 255, 212, 1) 0%,
      rgba(0, 212, 255, 1) 100%
    );
  }
}
