.root {
  flex-grow: 7;
}

.pdfviewerPage {
  margin: 25px;
  width: 700px;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: rgba(62, 62, 62, 0.15) 0px 25px 50px 0px;
  transition: filter 0.3s;
  user-select: none;
}

.pdfviewerPage:hover {
  filter: brightness(0.9);
}
