.settings-page {
  padding-top: 25px;

  &__heading {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
  }

  &__back-button {
    margin-right: 25px;
  }
}
