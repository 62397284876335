.AppBar {
  background-color: white;
}

.Toolbar {
  background-color: white;
  color: black;
}

.title {
  flex-grow: 1;
}
