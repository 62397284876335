.DetailsList {
  padding: 10px;
  overflow: hidden;
}

.footer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  margin-top: 20px;
}

.header {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
}
