.root {
  flex-grow: 1;
  width: 100%;
}

iframe {
  width: 100%;
  height: 700px;
}

.container {
  padding: 20px;
}

.center {
  text-align: center;
  align-content: center;
}

.error {
  color: #900d0d;
}

.viewer {
  display: flex;
  justify-content: space-between;
}
